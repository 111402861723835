<template>
	<div class="setup">
		<template v-if="mode === null">
			<div class="title">{{ $t('chatBot.setup.intro.headline') }}</div>

			<div class="description">{{ $t('chatBot.setup.intro.description') }}</div>

			<div class="buttons">
				<button type="button"
						@click="mode = 'code'">
					<i class="fas fa-code fa-fw" />
					<strong v-html="$t('chatBot.setup.technical')" />
				</button>

				<button type="button"
						class="outline"
						@click="mode = 'guide'">
					<i class="fas fa-cloud-question fa-fw" />
					<strong v-html="$t('chatBot.setup.nonTechnical')" />
				</button>
			</div>
		</template>

		<template v-if="mode === 'code'">
			<div class="title">{{ $t('chatBot.setup.code.title') }}</div>
			<div class="description">{{ $t('chatBot.setup.code.description') }}</div>

			<div class="copy-code">
				<pre ref="code"
					 class="language-javascript"
					 v-html="snippet"
				/>

				<!-- <button @click="copyToClipboard">
					<i class="far fa-copy fa-fw" />
					<span>{{ $t('chatBot.snippet.copy') }}</span>
				</button> -->
			</div>
		</template>

		<template v-if="mode === 'guide'">
			<div class="title">{{ $t('chatBot.setup.guide.title') }}</div>
			<div class="description">{{ $t('chatBot.setup.guide.description') }}</div>

			<ol class="guide">
				<li>
					<div class="step-title">{{ $t('chatBot.setup.guide.step1.title') }}</div>
					<div class="step-description">{{ $t('chatBot.setup.guide.step1.description') }}</div>
				</li>

				<li>
					<div class="step-title">{{ $t('chatBot.setup.guide.step2.title') }}</div>
					<div class="step-description">{{ $t('chatBot.setup.guide.step2.description') }}</div>
				</li>

				<li>
					<div class="step-title">{{ $t('chatBot.setup.guide.step3.title') }}</div>

					<div class="step-mail">
						<div v-html="$t('chatBot.setup.guide.step3.description')"></div>

						<pre ref="code"
							 class="language-javascript"
							v-html="snippet"
						/>
					</div>
				</li>
			</ol>
		</template>
	</div>
</template>

<style lang="scss" scoped>
div.setup {
	display: flex;
	flex-direction: column;
	padding: 25px;
	border-radius: 16px;
	flex-basis: calc(100% - 10px);
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

	> div.title {
		color: #000;
		font-weight: 800;
		font-size: 26px;
	}

	> div.description {
		color: #505050;
		font-size: 14px;
	}

	div.buttons {
		margin-top: 25px;

		display: flex;
		flex-direction: column;
		gap: 10px;

		> button {
			position: relative;
			display: flex;
			color: #fff;
			align-items: center;
			justify-content: center;
			padding: 18px 25px;
			font-size: 14px;
			font-weight: 600;
			border-radius: 8px;
			border: 2px solid transparent;
			background-color: #252525;

			i {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				font-size: 20px;
				padding: 0 0 0 20px;
			}

			&.outline {
				color: #252525;
				border-color: #252525;
				background-color: #fff;
			}

			&:hover {
				color: #fff;
				background-color: #000;
			}
		}
	}
}

ol.guide {
	padding: 0;
	margin: 25px 0 0 20px;

	> li {
		padding: 10px 25px;

		> div.step-title {
			color: #000;
			font-size: 18px;
			font-weight: 500;
		}

		> div.step-description {
			color: #383838;
			font-size: 14.5px;
			line-height: 150%;
			font-weight: 400;
		}

		> div.step-mail {
			padding: 20px;
			background: #f3f3f3;
			border: 1px solid #ccc;
			border-radius: 6px;
			margin-top: 10px;

			> div {
				font-family: monospace;
				white-space: break-spaces;
				margin-top: 10px;
			}
		}
	}
}

.copy-code {
	position: relative;
	background: #f6f6f6;
	border: 1px solid #ccc;
	border-radius: 6px;
	overflow: hidden;
	margin-top: 25px;

	> button {
		position: absolute;
		top: 0;
		right: 0;

		display: flex;
		align-items: center;

		border: 0;

		border-bottom: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-bottom-left-radius: 6px;

		background-color: #fff;

		gap: 6px;
		padding: 8px 10px;
		font-size: 12px;
		font-weight: 500;
	}

	> pre {
		border: 0;
		background-color: transparent;
		margin: 0;
	}
}


@media(max-width: 750px) {
	div.setup {
		> div.title {
			color: #000;
			font-weight: 800;
			font-size: 20px;
		}

		> div.description {
			color: #505050;
			font-size: 14px;
		}
	}
}

</style>

<script>
import Prism from 'prismjs'

const SNIPPET = `&lt;script src="https://chat.opnx.dk/js/app.js"&gt;&lt;/script&gt;
&lt;script type="text/javascript"&gt;
document.addEventListener('DOMContentLoaded', () =&gt; {
	if (window.ChatBot) {
		window.ChatBot.init();
	}
});
&lt;/script&gt;`

import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-javascript';

export default {
	data: () => ({
		mode: null,
		snippet: SNIPPET
	}),

	watch: {
		mode() {
			if (! this.mode) {
				return
			}

			this.$nextTick(() => {
				!! this.$refs.code && Prism.highlightElement(this.$refs.code)
			})
		}
	},

	methods: {
		copyToClipboard() {
			navigator.clipboard.writeText(SNIPPET);

			this.$notify({
				group: 'notify',
				type: 'success',
				title: $t('chatBot.snippet.title'),
				text: $t('chatBot.snippet.text'),
			});
		}
	}
}
</script>
